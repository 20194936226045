import accommodationSelector from "./functions/accommodation";
import dateSelector from "./functions/date";
import guestsSelector from "./functions/guests";
import inlineDate from "./functions/inline-date";
import inlineGuests from "./functions/inline-guests";
import searchFunction from "./functions/search";
import stickyBanner from "./functions/sticky-banner";

/**
 * The modal module.
 *
 * @return {void}
 */
export const booking = () => {
  let dateTrigger, guestTrigger, accommodationTrigger, searchTrigger;

  if (window.innerWidth < 1024) {
    dateTrigger = document.querySelector(
      ".js-booking-banner-date-trigger-mobile"
    );
    guestTrigger = document.querySelector(
      ".js-booking-banner-guests-trigger-mobile"
    );
    accommodationTrigger = document.querySelector(
      ".js-booking-banner-accommodation-trigger-mobile"
    );
    searchTrigger = document.querySelector(".js-mobile-booking-search");
    if (dateTrigger) {
      dateSelector(dateTrigger);
    }

    if (guestTrigger) {
      guestsSelector(guestTrigger);
    }
  } else {
    dateTrigger = document.querySelector(".js-booking-banner-date-trigger");
    guestTrigger = document.querySelector(".js-booking-banner-guests-trigger");
    accommodationTrigger = document.querySelector(
      ".js-booking-banner-accommodation-trigger"
    );
    searchTrigger = document.querySelector(".js-booking-banner-search");
    const inlineDateContainer = document.querySelector(
      ".js-inline-booking-date-container"
    );
    const inlineGuestsContainer = document.querySelector(
      ".js-inline-booking-guests-container"
    );

    if (inlineDateContainer) {
      inlineDate(inlineDateContainer);
    } else if (dateTrigger) {
      dateSelector(dateTrigger);
    }

    if (inlineGuestsContainer) {
      inlineGuests(inlineGuestsContainer);
    } else if (guestTrigger) {
      guestsSelector(guestTrigger);
    }
  }
  const sticky = document.querySelector(".js-sticky-booking-banner");
  const mobileTrigger = document.querySelector(".js-mobile-booking-trigger");
  const mobileBooking = document.querySelector(".js-mobile-booking-content");
  const mobileClose = document.querySelector(".js-mobile-booking-close");
  const bookingBannerBackground = document.querySelector(
    ".js-booking-banner-background"
  );

  const ipebbleContainer = document.querySelector(".ipebble-container");

  if (accommodationTrigger) {
    accommodationSelector(accommodationTrigger);
  }

  if (searchTrigger) {
    searchFunction(searchTrigger);
  }

  if (sticky) {
    // stickyBanner(sticky);
  }

  if (mobileTrigger) {
    mobileTrigger.addEventListener("click", () => {
      if (ipebbleContainer) {
        ipebbleContainer.scrollIntoView();
      } else {
        mobileBooking.classList.toggle("hidden");
        mobileBooking.classList.toggle("mobile-booking-active");
      }
    });

    mobileClose.addEventListener("click", () => {
      mobileBooking.classList.toggle("hidden");
      bookingBannerBackground.classList.add("hidden");
    });
  }
};

// Export the module
export default booking;
